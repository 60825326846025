import './MobNavigation.css';
import logo from "../../assets/images/logo.svg";
import burgerActive from "../../assets/images/burger-active.svg";

const MobNavigation = ({open, closeNavigation, scollTo}) => {
    const handleLinkClick = (block) => {
        scollTo(block);
        closeNavigation(false);
    };
    return (
        <div className={open ? "mob-navigation mob-navigation--active" : "mob-navigation"}>
            <div className="header">
                <img src={logo} alt="logo"/>
                <div className="navigation-burger" onClick={() => closeNavigation(false)}>
                    <img src={burgerActive} alt="burgerActive"/>
                </div>
            </div>
            <div className="mob-navigation-list-wrapper">
                <nav className="mob-navigation-list">
                    <div onClick={() => handleLinkClick('orgs')} className="navigation-item">Організатори</div>
                    <div onClick={() => handleLinkClick('program')} className="navigation-item">Програма</div>
                    <div onClick={() => handleLinkClick('partners')} className="navigation-item">Партнери</div>
                    <div onClick={() => handleLinkClick('buy')} className="navigation-item">Придбати квитки</div>
                </nav>
            </div>
        </div>
    );
};

export default MobNavigation;
