import './Description.css';
import descriptionBubble from '../../assets/images/description-bubble.png';

const Description = () => {
    return (
        <div className="description">
            <div className="description-left-side">
                <div className="description-title-first">
                    Ой на Івана та й на
                </div>
                <div className="description-title-second">
                    Купала...
                </div>
                <div className="description-img-text-wrapper">
                    <img src={descriptionBubble} alt="descriptionBubble"/>
                    <div className="description-img-text">
                        Івент приурочений даті Івана Купала, тож буде містити традиції, що зазвичай притаманні цьому
                        святу.
                    </div>
                </div>
            </div>
            <div className="description-right-side">
                <div className="description-right-side-top">
                    ЕТНОКОЛО — цей івент-колаборація двох найбільших медійних українських проєктів в ніші
                    affiliate-маркетингу <a rel="noreferrer" target='_blank' href="https://palai.media/">Палай медіа</a> та <a
                    target='_blank' rel="noreferrer" href="https://www.instagram.com/sempro.ukraine">SEMPRO.</a>
                    Організатор івентів з 10-річним досвідом
                    об’єднався з одним з найбільш якісних україномовних медіа про affiliate-маркетинг та трафік, щоб
                    створити дійсно унікальний івент.
                </div>
                <div className="description-right-side-mid"/>
                <div className="description-right-side-bottom">
                    ЕТНОКОЛО — це про українські традиції та стиль, що поєднуються із сучасними та вже перевіреними
                    часом активностями, що ви так любите на арбітражних мітапах.
                </div>
                <div className="description-img-text-wrapper-mob">
                    <div className="description-img-text-inner-mob">
                        <img src={descriptionBubble} alt="descriptionBubble"/>
                        <div className="description-img-text-mod">
                            Івент приурочений даті Івана Купала, тож буде містити традиції, що зазвичай притаманні цьому
                            святу.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Description;
