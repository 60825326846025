import './Footer.css';
import arrowRight from '../../assets/images/arrow-right.svg';
import cards from "../../assets/images/card.svg";
import inlineCards from "../../assets/images/inline-card.svg";
import usdt from "../../assets/images/usdt.svg";
import usdtInline from "../../assets/images/usdt-inline.svg";
import footer from "../../assets/images/footer.png";
import mail from "../../assets/images/mail.svg";
import tg from "../../assets/images/tg.svg";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-top">
                <div className="footer-top-first">
                    <div className="footer-top-first-left">
                        Всього 300 квитків
                    </div>
                    <div className="footer-top-first-right">
                        Локація знаходиться в межах Києва,<br/>
                        ви дізнаєтесь точне місце одразу <br/>
                        після придбання квитка.
                    </div>
                </div>
                <div className="footer-top-second">
                    <div className="footer-top-second-left">
                        На івенті буде чергувати охорона на випадок незваних <br/>
                        гостей. Безпека учасників -- наш пріорітет.
                    </div>
                    <div className="footer-top-second-right">
                        в продажу!
                    </div>
                </div>
            </div>
            <div className="footer-mid"/>
            <div className="footer-bottom">
                <div className="footer-bottom-first">
                    <div className="footer-bottom-first-price">
                        <span>Перші 50 квитків — $100</span>
                        <img src={arrowRight} alt="arrowRight"/>
                        <span>Наступні — $150</span>
                    </div>
                    <div className="footer-bottom-buy">
                        <a href='https://secure.wayforpay.com/payment/etnokolo' className="footer-bottom-buy-item">
                            <img src={arrowRight} alt="arrowRight" className="footer-bottom-buy-item-arrow"/>
                            <img src={cards} alt="cards" className="buy-cards-img"/>
                            <img src={inlineCards} alt="inlineCards" className="buy-cards-img-mob"/>
                            <span className="footer-bottom-buy-item-text">
                                Оплатити картою
                            </span>
                        </a>
                        <a href='https://t.me/palai_sales' className="footer-bottom-buy-item">
                            <img src={arrowRight} alt="arrowRight" className="footer-bottom-buy-item-arrow"/>
                            <img src={usdt} alt="usdt" className="buy-cards-img"/>
                            <img src={usdtInline} alt="usdtInline" className="buy-cards-img-mob"/>
                            <span className="footer-bottom-buy-item-text">
                                Оплатити в крипті
                            </span>
                        </a>
                    </div>
                    <div className="footer-bottom-text-mob">
                        <p>
                            Локація знаходиться в межах Києва, ви дізнаєтесь точне місце одразу після придбання квитка.
                        </p>
                        <p>
                            На івенті буде чергувати охорона на випадок незваних гостей. Безпека учасників -- наш
                            пріорітет.
                        </p>
                    </div>
                </div>
                <div className="footer-mid-mob"/>
                <div className="footer-bottom-second">
                    <img src={footer} alt="footer"/>
                </div>
                <div className="footer-bottom-third">
                    <div className="footer-bottom-third-title">
                        Питання та support:
                    </div>
                    <div className="footer-contact-wrapper">
                        <div className="footer-contact-btn">
                            <a href='mailto:admin@palai.media' className="footer-contact-btn-inner">
                                <img src={mail} alt="mail"/>
                            </a>
                        </div>

                        <div className="footer-contact-btn">
                            <a href='https://t.me/palai_sales' className="footer-contact-btn-inner">
                                <img src={tg} alt="tg"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-footer">
                <img src={footer} alt="footer"/>
                <span>
                    © 2022-2024 "Палай" медіа
                </span>
            </div>
        </div>
    );
};

export default Footer;
