import './InfoPartners.css';
import affhub from '../../assets/images/info-partners/affhub.png';
import ban from '../../assets/images/info-partners/ban.png';
import gate from '../../assets/images/info-partners/gate.png';
import uageek from '../../assets/images/info-partners/uageek.png';
import wintevents from '../../assets/images/info-partners/wintevents.png';
import already from '../../assets/images/info-partners/already.png';
import trident from '../../assets/images/info-partners/trident.png';
import yab from '../../assets/images/info-partners/yab.png';
import arbitrageUp from '../../assets/images/info-partners/arbitrageUp.png';
import onePartners from '../../assets/images/info-partners/onePartners.png';
import rise from '../../assets/images/info-partners/rise.png';
import cc from '../../assets/images/info-partners/cc.png';


const infoPartnersList = [
    {
        img: affhub,
        link: 'https://affhub.media/',
    },
    {
        img: ban,
        link: 'https://ban.media/',
    },
    {
        img: uageek,
        link: 'https://uageek.media/'
    },
    {
        img: gate,
        link: 'https://ggatemedia.com/',
    },
    {
        img: wintevents,
        link: 'https://wintevents.com/'
    },
    {
        img: already,
        link: 'https://alreadymedia.com/?utm_source=palaimedia&utm_medium=event&utm_campaign=etnokolo'
    },
    {
        img: trident,
        link: 'https://trident-media.agency/'
    },
    {
        img: yab,
        link: 'https://www.instagram.com/ya.brand.agency/'
    },
    {
        img: arbitrageUp,
        link: 'https://arbitrageup.net'
    },
    {
        img: onePartners,
        link: 'https://onepartners.io'
    },
    {
        img: rise,
        link: 'https://www.instagram.com/the.rise.club'
    },
    {
        img: cc,
        link: 'https://www.conversion-club.com'
    },
];

const InfoPartners = () => {
    return (
        <div className="info-partners">
            <div className="info-partners-title">
                Інфопартнери
            </div>
            <div className="info-partners-list">
                <div className="info-partners-list-inner">
                    {infoPartnersList.map((e, index) => (
                        <a href={e.link} target='_blank' rel="noreferrer" className="info-partners-list-item">
                            <img src={e.img} alt={`item-1`}/>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
};

export default InfoPartners;
