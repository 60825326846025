import {useEffect, useRef, useState} from "react";
import {Fade} from "react-awesome-reveal";
import './App.css';
import Header from "./components/header/Header";
import Banner from "./components/banner/Banner";
import Description from "./components/description/Description";
import Specialists from "./components/specialists/Specialists";
import Program from "./components/program/Program";
import Partners from "./components/partners/Partners";
import InfoPartners from "./components/info-partners/InfoPartners";
import Footer from "./components/footer/Footer";
import MobNavigation from "./components/mobNavigation/MobNavigation";

function App() {
    const orgsBlock = useRef(null);
    const programBlock = useRef(null);
    const partnersBlock = useRef(null);
    const buyBlock = useRef(null);

    const scrollMap = {
        orgs: orgsBlock,
        program: programBlock,
        partners: partnersBlock,
        buy: buyBlock,
    };

    const handleScrollTo = (blockName) => {
        scrollMap[blockName].current?.scrollIntoView();
    }

    const [mobNavigationOpen, setMobNavigationOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setMobNavigationOpen(false);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="root">
            <MobNavigation open={mobNavigationOpen} closeNavigation={setMobNavigationOpen} scollTo={handleScrollTo}/>
            <div className="content">
                <div className="inner">
                    <Header scollTo={handleScrollTo} openMobNav={() => setMobNavigationOpen(true)}/>
                    <Fade>
                        <Banner buyTicket={() => handleScrollTo('buy')}/>
                    </Fade>
                    <div ref={orgsBlock}>
                        <Fade>
                            <Description/>
                        </Fade>
                    </div>

                    <Fade>
                        <Specialists buyTicket={() => handleScrollTo('buy')}/>
                    </Fade>
                    <div ref={programBlock}>
                        <Fade>
                            <Program/>
                        </Fade>
                    </div>
                    <div ref={partnersBlock}>
                        <Fade>
                            <Partners/>
                        </Fade>
                    </div>
                    <Fade>
                        <InfoPartners/>
                    </Fade>
                    <div ref={buyBlock}>
                        <Fade>
                            <Footer/>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
