import './Specialists.css';
import buyTicketBubbleActive from "../../assets/images/buy-ticket-bubble-active.svg";

const specialists = [
    'медіабайєри',
    'SEO-спеціалісти',
    'ASO/ASA-спеціалісти',
    'УБТ-спеціалісти',
    'Власники бізнесів',
    'Представники компаній',
    'та інші нішеві експерти'
];

const Specialists = ({buyTicket}) => {
    return (
        <div className="specialists">
            <div className="specialists-title">
                <div className="specialists-title-first">
                    <div className="specialists-title-text">
                        Коло професіоналів
                    </div>
                    <div className='specialists-buy-btn' onClick={() => buyTicket()}>
                        <img src={buyTicketBubbleActive} alt="buyTicketBubble" className="specialists-buy-btn-img"/>
                    </div>
                </div>
                <div className="specialists-title-second">
                    в одному місці
                </div>
            </div>
            <div className="specialists-mid">
                <div className="specialists-mid-inner"/>
            </div>
            <div className="specialists-list">
                {specialists.map(e => <div className="specialists-list-item">{e}</div>)}
            </div>
        </div>
    );
};

export default Specialists;
