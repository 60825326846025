import './Partners.css';
import arrowRight from  '../../assets/images/arrow-right.svg'
import partner1 from  '../../assets/images/partners/partner1.svg'
import partner2 from  '../../assets/images/partners/partner2.svg'
import partner3 from  '../../assets/images/partners/partner3.svg'
import partner4 from  '../../assets/images/partners/partner4.svg'
import partner5 from  '../../assets/images/partners/partner5.svg'
import partner6 from  '../../assets/images/partners/partner6.svg'
import partner7 from  '../../assets/images/partners/partner7.svg'
import partner8 from  '../../assets/images/partners/partner8.svg'
import partner9 from  '../../assets/images/partners/partner9.svg'

const partnersList = [
    {
        img: partner3,
        text: <span className="partners-banner-item-text">Забезпечить <br/> тематичний мейк-ап</span>,
        link: 'https://affter.com/',
    },
    {
        img: partner4,
        text: <span className="partners-banner-item-text">Забезпечить <br/> welcome-подарунки</span>,
        link: 'https://aff-pro.com/',
    },
    {
        img: partner5,
        text: <span className="partners-banner-item-text">Забезпечить <br/> комфортний лаунж</span>,
        link: 'https://www.pin-up.partners/',
    },
    {
        img: partner5,
        text: <span className="partners-banner-item-text">Забезпечить <br/> авторськими напоями</span>,
        link: 'https://www.pin-up.partners/',
    },
    {
        img: partner6,
        text: <span className="partners-banner-item-text">Забезпечить покерний <br/> турнір та рулетку</span>,
        link: 'https://kng.partners/uk',
    },
    {
        img: partner7,
        text: <span className="partners-banner-item-text">Забезпечить <br/> найдимніші кальяни</span>,
        link: 'https://spaceprofit.team/',
    },
    {
        img: partner8,
        text: <span className="partners-banner-item-text">Маркетингова <br/> підтримка івенту</span>,
        link: 'https://ctrl-cash.com/'
    },
    {
        img: partner9,
        text: <span className="partners-banner-item-text">Партнер івенту&nbsp;&nbsp;</span>,
        link: 'https://trusteeglobal.com/'
    },
]

const Partners = () => {
    return (
        <div className="partners">
            <div className="partners-top">
                <div className="partners-title">
                    <div className="partners-title-first">
                        Партнери
                        <img src={arrowRight} alt="arrowRight"/>
                    </div>
                    <div className="partners-title-second">
                        івенту
                    </div>
                </div>
                <div className="partners-banners-wrapper">
                    <div className="partners-banners-inner">
                        <a rel="noreferrer" target='_blank' href="https://gamehub.partners/" className="partners-banner-item">
                            <img src={arrowRight} alt="arrowRight" className="partners-banner-item-arrow"/>
                            <img src={partner1} alt="palai"/>
                            <span className="partners-banner-item-text">
                                Генеральний <br/>
                                спонсор
                            </span>
                        </a>
                        <a rel="noreferrer" target='_blank' href="https://t.me/skywalkersforce" className="partners-banner-item">
                            <img src={arrowRight} alt="arrowRight" className="partners-banner-item-arrow"/>
                            <img src={partner2} alt="sempro"/>
                            <span className="partners-banner-item-text">
                                Генеральний <br/>
                                спонсор
                            </span>
                        </a>
                    </div>
                </div>
            </div>

            <div className="partners-mid"/>

            <div className="partners-bottom">
                {partnersList.map((e, index) => (
                    <a rel="noreferrer" target='_blank' href={e.link} className="partners-bottom-item" key={`partners-bottom-item-${index}`}>
                        <img src={arrowRight} alt="arrowRight" className="partners-bottom-item-arrow"/>
                        <img src={e.img} alt="palai" className="partners-bottom-item-logo"/>
                        {e.text}
                    </a>
                ))}
            </div>

        </div>
    );
}

export default Partners;
