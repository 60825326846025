import {useRef} from "react";
import Slider from "react-slick";
import './Program.css';
import arrowLeft from '../../assets/images/arrow-left.svg';
import arrowRight from '../../assets/images/arrow-right.svg';
import program1 from '../../assets/images/program-slider/program1.svg';
import program2 from '../../assets/images/program-slider/program2.svg';
import program3 from '../../assets/images/program-slider/program3.svg';
import program4 from '../../assets/images/program-slider/program4.svg';
import program5 from '../../assets/images/program-slider/program5.svg';
import program6 from '../../assets/images/program-slider/program6.svg';
import program7 from '../../assets/images/program-slider/program7.svg';
import program8 from '../../assets/images/program-slider/program8.svg';
import program9 from '../../assets/images/program-slider/program9.svg';
import program10 from '../../assets/images/program-slider/program10.svg';
import program11 from '../../assets/images/program-slider/program11.svg';
import program12 from '../../assets/images/program-slider/program12.svg';
import program13 from '../../assets/images/program-slider/program13.svg';


const slides = [
    program1,
    program2,
    program3,
    program4,
    program5,
    program6,
    program7,
    program8,
    program9,
    program10,
    program11,
    program12,
    program13,
];

const Program = () => {
    const sliderRef = useRef();
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        adaptiveHeight: true,
        variableWidth: true,
        autoplay: true,
    };
    return (
        <div className="program">
            <div className="program-top">
                <div className="program-title">
                    <div className="program-title-first">
                        Програма
                    </div>
                    <div className="program-title-second">
                        ЕТНОКОЛО
                    </div>
                </div>
                <div className="program-nav">
                    <div className="program-nav-btn">
                        <div className="program-nav-btn-inner" onClick={() => sliderRef?.current?.slickPrev()}>
                            <img src={arrowLeft} alt="arrowLeft"/>
                        </div>
                    </div>
                    <div className="program-nav-btn">
                        <div className="program-nav-btn-inner" onClick={() => sliderRef?.current?.slickNext()}>
                            <img src={arrowRight} alt="arrowRight"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="program-bottom">
                <Slider ref={sliderRef} {...settings}>
                    {slides.map((e, index) =>
                        <div className="program-slide">
                            <img src={e} alt={`slide-item-${index}`}/>
                        </div>
                    )}
                </Slider>
            </div>
        </div>
    );
};

export default Program;
