import './Banner.css';
import palai from '../../assets/images/palai.svg';
import sempro from '../../assets/images/sempro.svg';
import marker from '../../assets/images/marker.svg';
import buyTicketBubbleActive from "../../assets/images/buy-ticket-bubble-active.svg";
import banner from "../../assets/images/banner.png";

const Banner = ({buyTicket}) => {
    return (
        <div className="banner">
            <div className="banner-date">
                <div className="banner-date-text">
                    04/07 <br/> /2024
                </div>
                <div className='buy-btn' onClick={() => buyTicket()}>
                    <img src={buyTicketBubbleActive} alt="buyTicketBubble" className="buy-btn-img"/>
                </div>
            </div>
            <div className="banner-info">
                <div className="banner-info-sponsors">
                    <a target='_blank' href="https://palai.media/" className="banner-info-sponsors-item"
                       rel="noreferrer">
                        <img src={palai} alt="palai"/>
                    </a>
                    <a target='_blank' href="https://www.instagram.com/sempro.ukraine"
                       className="banner-info-sponsors-item" rel="noreferrer">
                        <img src={sempro} alt="sempro"/>
                    </a>
                </div>
                <div className="banner-date-mob">
                    <div className="banner-date-mob-top">
                        <div className="banner-date-mob-top-first">
                            04/07
                        </div>
                        <div className="banner-date-mob-top-second">
                            <img src={marker} alt="marker"/>
                            Київ, <br/>
                            Secret Place
                        </div>
                    </div>
                    <div className="banner-date-mob-bottom">
                        <span className="banner-date-mob-bottom-text">
                            /2024
                        </span>
                        <div className='buy-btn' onClick={() => buyTicket()}>
                            <img src={buyTicketBubbleActive} alt="buyTicketBubble" className="buy-btn-img"/>
                        </div>
                    </div>
                </div>
                <div className="banner-img-mob">
                    <img src={banner} alt="banner"/>
                </div>
                <div className="banner-slide-text-mob">
                    <div className="marquee-wrapper">
                        <div className="marquee">
                            <p>
                                Купальські вечорниці для афілейт комʼюніті&nbsp;

                            </p>
                            <p>
                                Купальські вечорниці для афілейт комʼюніті&nbsp;
                            </p>
                        </div>
                    </div>
                </div>
                <div className="banner-info-text">
                    <div className="banner-info-text-title">
                        Купальські вечорниці для афілейт комʼюніті
                    </div>
                    <div className="banner-info-text-location">
                        <img src={marker} alt="marker"/>
                        Київ, Secret Place
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Banner;
