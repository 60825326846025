import './Header.css';
import logo from '../../assets/images/logo.svg'
import burger from '../../assets/images/burger.svg'

const Header = ({scollTo, openMobNav}) => {
    return (
        <header className="header">
            <img src={logo} alt="logo"/>
            <nav className="navigation">
                <div onClick={() => scollTo('orgs')} className="navigation-item">Організатори</div>
                <div onClick={() => scollTo('program')} className="navigation-item">Програма</div>
                <div onClick={() => scollTo('partners')} className="navigation-item">Партнери</div>
                <div onClick={() => scollTo('buy')} className="navigation-item">Придбати квитки</div>
            </nav>
            <div className="navigation-burger" onClick={openMobNav}>
                <img src={burger} alt="burger"/>
            </div>
        </header>
    );
};

export default Header;
